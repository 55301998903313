<template>
    <div :class="{'has-text': $slots.default}">
        <label class="checkbox-container" :class="{error: showError != false}">
            <div v-if="required" class="required">*</div>
            <input type="checkbox" :checked="isChecked" @change="toggleCheck" :required="required">
            <span class="checkmark" :class="{'bg-white': bgWhite}" :style="{'--active-bg-color' : activeBackgroundColor}"></span>
        </label>
        <span class="description" v-if="$slots.default">
            <slot></slot>
        </span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        bgWhite: {
            default: false,
            type: Boolean,
        },
        value: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        showError: {
            type: Boolean,
            default: false
        },
        activeBackgroundColor: {
            type: String,
            default: "#3BEA7E"
        }
    },

    event: 'change',

    computed: {
        isChecked: {
            get() {
                return this.value;
            },
            set(isChecked) {
                this.$emit("input", isChecked);
            },
        },
    },

    methods: {
        toggleCheck(evt) {
            this.isChecked = !this.isChecked;
            this.$nextTick(() => {
                this.$emit('change', evt)
            })
        },
    }
  }
</script>
  
<style scoped lang="scss">
    @import '@/scss/variables';

    .has-text {
        display: flex;
        gap: 15px;
    }
    .checkbox-container {
        display: flex;
        position: relative;
        width: 25px;
        height: 25px;
        margin: 0;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:focus {
                outline: 2px solid $tr-green;
            }

        &.error {
            .checkmark {
                outline: 2px solid $tr-pink;
            }
        }

        .required {
            position: absolute;
            left: 28px;
            top: -8px;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            
            &:focus + .checkmark {
                outline: 2px solid $tr-green;
            }
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
        }

        &:hover input ~ .checkmark {
            background-color: #ccc !important;
        }
    
        input:checked ~ .checkmark {
            background-color: var(--active-bg-color) !important;
        }
    
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
    
        input:checked ~ .checkmark:after {
            display: block;
        }
    
        .checkmark:after {
            left: 9px;
            top: 3px;
            width: 8px;
            height: 15px;
            border: solid $tr-white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .description {
            padding-left: 5px;
        }
    }

    
    
  </style>